import { render, staticRenderFns } from "./csv-download-link.vue?vue&type=template&id=590d153d&scoped=true"
import script from "./csv-download-link.vue?vue&type=script&lang=js"
export * from "./csv-download-link.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590d153d",
  null
  
)

export default component.exports