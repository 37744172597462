// 特定のアンケートの集計結果

<template lang="pug">
el-card.admin-survey-result

  article
    .go-back(style='margin-bottom: 0.5rem')
      el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

    h1 「{{ title }}」の結果

    el-row(v-if='answeredNum')
      el-col(:span='12')
        p.status(v-if='answeredNum') 回答数 {{ answeredNum }} / 対象数 {{ targetNum ? targetNum : '不明' }}
      el-col(:span='12')
        div(style='text-align: right')
          csv-download-link(
            :filename='`${title}の集計結果.csv`',
            :data-array-array='dataForCsv'
          ) Excel用ファイル(CSV)のダウンロード

    el-table.mt-3(
      :data='items',
      height='500px',
      border
    )
      el-table-column(
        v-for='head in heads',
        :prop='head.key',
        :label='head.name',
        :key='head.key',
        sortable
      )
</template>

<script>
import getSurveyResultsApi from '@/api/admin/get-survey-results'

import CsvDownloadLink from '@/components/csv-download-link'

export default {
  name: 'AdminSurveyResult',

  components: {
    CsvDownloadLink,
  },

  data() {
    return {
      title: '',
      targetNum: null,
      answeredNum: null,
      heads: [],
      items: [],
    }
  },

  async created() {
    const response = await getSurveyResultsApi(this.surveyId)
    if (!response.ok) return
    this.title = response.payload.title
    this.targetNum = response.payload.targetNum
    this.answeredNum = response.payload.answeredNum
    this.heads = response.payload.heads
    this.items = response.payload.items
  },

  computed: {
    surveyId() {
      return parseInt(this.$route.params.surveyId)
    },
    dataForCsv() {
      const arrayArray = []
      arrayArray.push(this.heads.map((head) => head.name))
      this.items.forEach((obj) => {
        arrayArray.push(this.heads.map((head) => obj[head.key]))
      })
      return arrayArray
    },
  },
}
</script>

<style lang="sass" scoped>
.admin-survey-result

  article
    padding-bottom: 3rem

    h1
      font-size: 1.3rem
      color: $grey-dark

    p.status
      margin-top: 1rem
</style>
