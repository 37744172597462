// CSV形式でダウンロードさせるリンク

<template>
  <a
    class="el-button el-button--primary"
    style="text-decoration: none"
    :download="this.isIE ? null : filename"
    :href="dataUri"
    @click="downloadForIE"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: ['dataArrayArray', 'filename'],
  computed: {
    // IEかどうか。IEだとblobの渡し方が異なる
    isIE() {
      return !!window.navigator.msSaveOrOpenBlob
    },
    dataUri() {
      if (!this.dataArrayArray) return null
      if (this.isIE) return null
      const blob = this._createCsvBlob()
      return (window.URL || window.webkitURL).createObjectURL(blob)
    },
  },
  methods: {
    _createCsvBlob() {
      let csv = this.dataArrayArray
        .map((array) => {
          return array.map((str) => this._quote(str)).join(',')
        })
        .join('\r\n')
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      return new Blob([bom, csv], { type: 'text/csv' })
    },
    // 値をダブルクォートで囲う
    _quote(value) {
      if (value === null || value === undefined) return ''
      // 強制的にstringに変換
      const str = '' + value
      return `"${str.replace(/"/g, '""')}"`
    },
    // クリックをトリガにしてIEにファイルをダウンロードさせる
    downloadForIE() {
      if (!this.isIE) return
      window.navigator.msSaveOrOpenBlob(this._createCsvBlob(), this.filename)
    },
  },
}
</script>

<style lang="sass" scoped></style>
